/*
Copyright 2024 New Vector Ltd.
Copyright 2021, 2022 Šimon Brandner <simon.bra.ag@gmail.com>
Copyright 2015, 2016 , 2020, 2021 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only OR LicenseRef-Element-Commercial
Please see LICENSE files in the repository root for full details.
*/

.mx_VideoFeed {
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    border: transparent 2px solid;
    display: flex;
    border-radius: 4px;

    &.mx_VideoFeed_secondary {
        position: absolute;
        right: 12px;
        top: 15px;
        width: 30%;
    }

    &.mx_VideoFeed_voice {
        background-color: $inverted-bg-color;

        display: flex;
        justify-content: center;
        align-items: center;

        &:not(.mx_VideoFeed_primary) {
            aspect-ratio: 16 / 9;
        }
    }

    .mx_VideoFeed_video {
        height: 100%;
        width: 100%;
        border-radius: 4px;
        background-color: #000000;

        &.mx_VideoFeed_video_mirror {
            transform: scale(-1, 1);
        }
    }

    .mx_VideoFeed_mic {
        position: absolute;
        left: 6px;
        bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        width: 24px;
        height: 24px;

        background-color: rgba(0, 0, 0, 0.5); /* Same on both themes */
        border-radius: 100%;

        &::before {
            position: absolute;
            content: "";
            width: 17px;
            height: 17px;
            mask-repeat: no-repeat;
            mask-size: contain;
            mask-position: center;
            background-color: white; /* Same on both themes */
            border-radius: 7px;
        }

        &.mx_VideoFeed_mic_muted::before {
            mask-image: url("@vector-im/compound-design-tokens/icons/mic-off-solid.svg");
        }

        &.mx_VideoFeed_mic_unmuted::before {
            mask-image: url("@vector-im/compound-design-tokens/icons/mic-on-solid.svg");
        }
    }
}
